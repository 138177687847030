import { HeroRightFarmer } from "./HeroImageHandlers"
import { HeroTitleFarmer, HeroSubtitleFarmer } from "../Styling/Titles"
import { Ingress } from "../Styling/Body"
import ShareModal from "../Modals/ShareModal"

import { BackArrowButton } from "../Graphics/Arrow"

import HeroBannerProps from "../Hero/HeroTypes"
import getMainPath from "../Common/MainPaths"

const HeroFarmerPage = (props: HeroBannerProps) => {
  const mainPath = getMainPath("farmer")

  return (
    <>
      <header>
        <div className=" max-w-240 mx-auto">
          <div className="grid grid-flow-col grid-cols-1 grid-rows-2 md:grid-rows-1 md:grid-cols-2  md:col-start-2  lg:grid-rows-1 lg:grid-cols-2 gap-0   ">
            <div className="flex justify-center bg-knapplysgronn-500 order-last md:order-first px-6 sm:py-12 lg:pt-16 ">
              <div className="text-center  my-auto sm:my-0 break-normal  max-w-m md:max-w-md lg:max-w-xl ">
                <div className="hidden items-center md:contents z-30">
                  <BackArrowButton url={mainPath} title="Produsenter" />
                </div>
                <div className="mt-8 sm:mt-14 md:mt-9">
                  <HeroTitleFarmer>{props.title}</HeroTitleFarmer>
                </div>
                <div className="mt-8 sm:mt-20 px-4 xl:mb-8 xl:pb-4">
                  <HeroSubtitleFarmer>{props.subTitle}</HeroSubtitleFarmer>
                </div>
              </div>
            </div>
            <div className="lg:min-h-192">
              <HeroRightFarmer {...props} />
            </div>
          </div>
        </div>
        <div className="bg-white relative mx-auto ">
          <div className="text-center mt-16  text-author-500 text-opacity-50 ">
            <ShareModal title={props.title} url={props.href} className="my-3" />
          </div>
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className=" px-4 sm:px-0 max-w-s sm:max-w-lg md:max-w-xl xl:max-w-84 mx-auto mt-20">
              <Ingress>{props.ingress}</Ingress>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeroFarmerPage
