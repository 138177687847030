import { graphql } from "gatsby"

import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import SEO, { getSeoProps } from "../components/Common/SEO"

import { RelatedSectionProps, RelatedSection } from "../components/Sections/RelatedSection"

import HeroFarmerPage from "../components/Hero/HeroFarmerPage"
import getBannerProps from "../components/Hero/getHeroBannerProps"

import getMainPath from "../components/Common/MainPaths"

/**
 * @description Denne skal vise portrett intervjue med bonde.
 * Det skal også være en faktaboks. Dvs at vi må lage faktaboks som en innholdstype. Dvs at vi må lage en faktabox.
 * Så skal vi vise relaterte innhold. Dvs andre bonde objekter. sorter på nyeste først. inntil 3 stk.
 * @param param0
 */
const FarmerTemplate = ({ location, data }) => {
  const mainPath = getMainPath("farmer")

  const { contentfulFarmer: content } = data

  let {
    allContentfulFarmer: { nodes: allContent },
  } = data

  if (allContent !== null && allContent !== undefined) {
    allContent = allContent.filter((x) => x.id !== content.id)

    if (allContent.length >= 3) {
      const size = 3
      allContent = allContent.slice(0, size)
    }

    allContent.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  }

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content, location.href)

  const relatedProps: RelatedSectionProps = {
    title: "Flere produsenter",
    path: "nb" + mainPath,
    data: allContent,
  }

  return (
    <>
      <SEO {...helmetProps} />
      <main>
        <article>
          <div className="bg-white overflow-hidden  max-w-200 mx-auto ">
            <HeroFarmerPage {...heroBannerProps} />

            <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  mb-48">
              <div className="mx-auto max-w-screen md:max-w-2xl pl-4 md:px-8 lg:max-w-3xl xl:max-w-116">
                <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
              </div>
            </div>
          </div>
        </article>
      </main>
      <RelatedSection {...relatedProps} />
    </>
  )
}

export default FarmerTemplate

export const farmerQuery = graphql`
  query farmersQuery($slug: String!) {
    contentfulFarmer(slug: { eq: $slug }, node_locale: { eq: "nb-NO" }) {
      id
      node_locale
      slug
      title
      subtitle
      metadataDescription
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      videoUrl
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
            caption
          }
        }
        url
        secure_url
        width
        height
        format
      }
      author {
        name
        firstName
        lastName
        twitter
        twitterId
      }
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
    }
    allContentfulFarmer(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        slug
        id
        node_locale
        metadataDescription
        author {
          name
          firstName
          lastName
          twitter
          twitterId
        }
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        contentful_id
        title
        subtitle
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
              caption
            }
          }
          url
          secure_url
          width
          height
          format
        }
      }
    }
  }
`
